import React, { useState } from "react";
import Field from "./field.js";
import mimeTypes from "./mimes.js";

const extensionsToMimes = (exts) => {
  const output = [];
  if (!exts) {
    return "";
  }
  exts = exts
    .split(/[, ]/g)
    .filter(e => e.length)
    .map(e => e.trim().replace(/^\./g, ""));
  output.push(...exts);
  exts.forEach(e => {
    const foundMime = mimeTypes.find(mime => mime.extensions.includes(e));
    if (!foundMime) {
      return;
    }
    output.push(...foundMime.extensions, ...foundMime.mimes);
  });
  return [...new Set(output)].join(",");
}

const Fileupload = (props) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [chosenFiles, setChosenFiles] = useState([]);
  const validationFunction = (value) => {
    const files = [...(value.files || [])];
    const maxSize = (props.maxFileSize || 32)*1024*1024;
    // tester la taille
    if (files.reduce((a,b) => {
      return (a.size || a) + (b.size || b)
    }, 0) > maxSize) {
      setErrorMessage("La taille maximale autorisée est dépassée.");
      return false;
    }
    // tester le nombre
    if (files.length < 1) {
      setErrorMessage("Ce champ est obligatoire, vous devez envoyer au moins un fichier.");
      return false;
    }
    if (props.maxFiles && files.length > parseInt(props.maxFiles)) {
      setErrorMessage("Le nombre maximum de fichiers est dépassé.");
      return false;
    }
    // tester les extensions
    if (props.allowedExtensions) {
      const mimes = extensionsToMimes(props.allowedExtensions);
      if (!files.every(f => mimes.includes(f.type))) {
        setErrorMessage("Certains fichiers ne sont pas du type autorisé.");
        return false;
      }
    }
    return true;
  };
  const onChange = (e) => {
    setChosenFiles([...e.target.files || []]);
  }
  const Description = (() => {
    const maxSize = (props.maxFileSize !== "") && (props.maxFileSize+"Mo") || "32Mo";
    const maxFiles = props.maxFiles;
    const extensions = (props.allowedExtensions)
      ? props.allowedExtensions.split(/[, ]/g)
          .filter(e => e.length)
          .map(e => e.trim().replace(/^\./g, ""))
          .join(", ")
      : false;
    const output = [`Taille max. ${maxSize}`];
    if (extensions) {
      output.push(`Type de fichiers acceptés : ${extensions}`);
    }
    if (maxFiles) {
      output.push(`Nombre maximum de fichiers : ${maxFiles}`);
    }
    return (
      <div className="upload-wrapper">
        {
          chosenFiles.length
          ? <>
              <p className="upload-title">
                { chosenFiles.length } fichier{chosenFiles.length > 1 ? "s":""} sélectionné{chosenFiles.length > 1 ? "s":""}
              </p>
              <p className="upload-size">
                { chosenFiles.map(e => e.name).join(", ") }
              </p>
            </>
          : <>
              <p className="upload-title">
                Téléversez le fichier ou cliquez ici
              </p>
              <p className="upload-size">
                { output.join(" / ") }
              </p>
            </>
        }
      </div>
    )
  })();

  return (<Field
    {...props}
    neighbour={ Description }
    accept={ extensionsToMimes(props.allowedExtensions) }
    type="file"
    validationFunction={ validationFunction }
    onChange={ onChange }
  />);
};

export default Fileupload;
